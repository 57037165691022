<template>
  <div class="container">
    <div class="introduction">
      <div class="intro1" id="intro1">
        <div class="intro1text ama60">
          IWI 2.0 - waar Indische geschiedenis geschreven en gepresenteerd
          wordt
        </div>
      </div>
      <div class="intro2" id="intro2">
        <div class="intro2text ama40">
          Indische geschiedenis begon in Azie. Van daaruit waaierden Indische
          mensen uit over de hele wereld<br /><br />Ook naar Nederland
        </div>
      </div>
      <div class="intro3" id="intro3">
        <div class="intro3text ama40" id="intro3">
          Een geschiedenis van Indische mensen, opgebouwd uit al hun
          herinneringen en vertellingen<br /><br />Zo geschiedenis schrijven
          was het idee van schrijver-verteller Jan Boon (1911-1974)
        </div>
      </div>
      <div class="intro4" id="intro4">
        <div class="intro3text ama40" id="intro3">
          In zijn tijdschrift <i>Tong Tong</i> verzamelde hij al die herinneringen aan
          Indie<br /><br />Zo gaf hij - voor het eerst - vorm aan het
          Indische
        </div>
      </div>
    </div>
    <div id="nav">
      <div class="diamonds">
        <div
          id="intro1diamond"
          class="diamondshighlight"
          @click="goto('intro1diamond')"
        >
          <!-- <InlineSvg :src="require('../assets/images/highlightdiamond.svg')"/> -->
        </div>
        <div
          id="intro2diamond"
          class="diamondsnormal"
          @click="goto('intro2diamond')"
        >
          <!-- <InlineSvg :src="require('../assets/images/diamond.svg')" @click="goto('intro2')"/> -->
        </div>
        <div
          id="intro3diamond"
          class="diamondsnormal"
          @click="goto('intro3diamond')"
        >
          <!-- <InlineSvg :src="require('../assets/images/diamond.svg')" @click="goto('intro3')"/> -->
        </div>
        <div
          id="intro4diamond"
          class="diamondsnormal"
          @click="goto('intro4diamond')"
        >
          <!-- <InlineSvg :src="require('../assets/images/diamond.svg')" @click="goto('intro4')"/> -->
        </div>
      </div>
      <div class="skip ama16" @click="skip()">
        skip intro
      </div>
    </div>
  </div>
  <div id="homepage" class="homepage">
    <div class="tjalie">
      <div class="tjalietext ama60">
        <b>Tjalieclopedie</b> presenteert het leven en werk van Jan Boon alias
        Tjalie Robinson alias Vincent Mahieu
        <div class="tjaliebutton pop16" @click="gotopage('tjalie')">
          Ga naar de Tjalieclopedie&nbsp;&nbsp;<InlineSvg
            :src="require('../assets/images/rightarrow.svg')"
          />
        </div>
      </div>

      <div class="piekir">
        <div class="piekirtext ama40">
          <b>Piekirpedia</b> presenteert Indische geschiedenis. Voeg je eigen
          (familie) geschedienis toe
          <div class="piekirbutton pop16" @click="gotopage('piekirpedia')">
            Ga naar de Piekirpedia&nbsp;&nbsp;<InlineSvg
              :src="require('../assets/images/rightarrow.svg')"
            />
          </div>
        </div>
      </div>
      <div class="eternite">
        <div class="eternitetext ama40">
          <b>Theatre Eternite</b>, beeld, audio, artikelen en nog veel meer uit
          de IWI-collectie
          <div class="eternitebutton pop16" @click="gotopage('eternite')">
            Kijk, lees en luister<InlineSvg
              :src="require('../assets/images/rightarrow.svg')"
              style="margin-left: 10px;"
            />
          </div>
        </div>
      </div>
      <!-- <div class="collectie p-d-flex"> -->
      <div class="collectietext ama30 p-jc-between">
        <div>IWI Collectie</div>
        <div class="doorzoek pop16" @click="gotopage('collectie')">
          Doorzoek naar de collectie<InlineSvg
            :src="require('../assets/images/rightarrow.svg')"
            style="margin-left: 10px;"
          />
        </div>
      </div>
      <!-- </div> -->
      <div class="collectiebottom p-d-flex">
        <div class="collectiegroup"></div>
        <ul class="collectiebuttons ama20">
          <li class="collectiebutton" @click="gotopage('eternite')">
            Beeldarchief
          </li>
          <li class="collectiebutton" @click="gotopage('eternite')">
            Documenten
          </li>
          <li class="collectiebutton" @click="gotopage('eternite')">
            Bibliotheek
          </li>
          <li class="collectiebutton" @click="gotopage('eternite')">
            Voorwerpen
          </li>
          <li class="collectiebutton" @click="gotopage('eternite')">
            Geluidsarchief
          </li>
          <li class="collectiebutton" @click="gotopage('eternite')">Prenten</li>
          <li class="collectiebutton" @click="gotopage('eternite')">
            Indisch geheugen
          </li>
        </ul>
        <div class="collectiegroup"></div>
      </div>
    </div>
    <div class="collectie2"></div>
  </div>
</template>

<script>
export default {
  methods: {
    skip() {
      document.getElementById("homepage").scrollIntoView(false)
      document.getElementById("nav").style.display = "none"
    },
    goto(div) {
      document.getElementById("intro1diamond").className = "diamondsnormal"
      document.getElementById("intro2diamond").className = "diamondsnormal"
      document.getElementById("intro3diamond").className = "diamondsnormal"
      document.getElementById("intro4diamond").className = "diamondsnormal"
      document.getElementById(div).className = "diamondshighlight"
      document.getElementById(div.replace("diamond", "")).scrollIntoView(false)
    },
    gotopage(page) {
      this.$router.push(`/${page}`)
    },
  },
  mounted() {
    document.getElementById("intro1").scrollIntoView(true)
  },
}
</script>

<style>
*::-webkit-scrollbar {
 /* display: none;*/
}

.container {
  scrollbar-width: none;
  -ms-overflow-style: none;
  overflow: hidden;
  background-image: url("../assets/images/introbackground.jpg") !important; 
}
.introduction {
  background-color: #201022;
}
.homepage {
  background-color: #201022;
  height: 100vh;
  color: white;
}
.skip {
  border: solid 1px #ef5e33;
  cursor: pointer;
  position: fixed;
  top: 822px;
  padding: 5px;
  color: white;
  width: 80px;
  right: 50px;
}
.diamonds {
  cursor: pointer;
  position: fixed;
  top: 475px;
  margin-right: 30px;
  right: 0px;
  width: 30px;
  text-align: center;
}
.diamondshighlight {
  background-image: url("../assets/images/highlightdiamond.svg");
  width: 25px;
  height: 25px;
  margin: 0 auto 5px auto;
}
.diamondsnormal {
  background-image: url("../assets/images/diamond.svg");
  width: 15px;
  height: 15px;
  margin: 0 auto 5px auto;
}
.intro1 {
  height: 100vh;
}
.intro1text {
  color: white;
  width: 760px;
  height: 219px;
  position: relative;
  top: 521px;
  left: 187px;
}
.intro2 {
  height: 100vh;
}
.intro2text {
  color: white;
  width: 710px;
  position: relative;
  top: 387px;
  left: 187px;
}
.intro3 {
  height: 100vh;
}
.intro3text {
  color: white;
  width: 800px;
  position: relative;
  top: 377px;
  left: 187px;
}
.intro4 {
  height: 100vh;
}
.intro4text {
  color: white;
  width: 583px;
  height: 314px;
  position: relative;
  top: 387px;
  left: 187px;
}
.intro5 {
  height: 100vh;
}
.tjalie {
  height: 855px;
  background: url("../assets/images/tjalieintrobackground.jpg") no-repeat fixed
    center;
  background-size: cover;
}
.collectie {
  position: absolute;
  height: 200px;
  margin-top: 1650px;
  width: 100%;
  background-color: #201022;
  padding-left: 150px;
}
.collectiebottom {
  position: absolute;
  height: 200px;
  margin-top: 1700px;
  width: 100%;
  background-color: #201022;
}
.collectie2 {
  height: 855px;
  background-color: #201022;
}
.piekir {
  height: 693px;
  background: url("../assets/images/piekirintrobackground.jpg") no-repeat fixed
    center;
  background-size: cover;
  position: absolute;
  margin-top: 645px;
  margin-left: 80px;
  width: calc(100% - 160px);
}
.eternite {
  height: 500px;
  margin-left: 150px;
  background: url("../assets/images/eterniteintrobackground.jpg") no-repeat;
  background-size: cover;
  background-position: left top;
  position: absolute;
  width: calc(100% - 300px);
  margin-top: 1100px;
}
.tjalietext {
  position: absolute;
  margin-left: 80px;
  margin-top: 154px;
  width: 940px;
}
.tjaliebutton {
  margin-top: 30px;
  background-color: #ef5e33;
  width: 280px;
  height: 50px;
  padding: 11px;
  cursor: pointer;
}
.piekirtext {
  margin-left: 85px;
  padding-top: 85px;
  width: 600px;
}
.piekirbutton {
  margin-top: 30px;
  background-color: #ef5e33;
  width: 260px;
  height: 50px;
  padding: 11px;
  cursor: pointer;
}
.eternitetext {
  margin-left: 80px;
  padding-top: 70px;
  width: 500px;
  font-weight: 100;
}
.eternitebutton {
  cursor: pointer;
  text-decoration: underline;
}
.collectietext {
  width: calc(100% - 150px);
  position: absolute;
  margin-top: 1660px;
  background-color: #201022;
  padding-left: 150px;
  display: flex;
}
.collectiebuttons {
  margin-top: 10px;
  flex-grow: 1;
  display: flex;
  padding-inline-start: 0px;
  list-style: none;
  height: fit-content;
}
.collectiebutton {
  background-color: #67a178;
  padding: 5px 10px 8px 10px;
  margin-right: 10px;
  cursor: pointer;
  flex-grow: 1;
}
.collectiebutton:last-child {
  margin-right: 0px;
}
.collectiegroup {
  flex-basis: 150px;
  flex-shrink: 0;
}
.doorzoek {
  font-weight: 100;
  text-decoration: underline;
  cursor: pointer;
}
</style>
